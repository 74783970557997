.footer-section {
  margin-top: 6rem;
  background: var(--light-one);
}

.footer {
  display: grid;
  justify-content: center;
  margin-top: 2rem;
  padding-top: 2rem;
  background: var(--dark-one);
}

.bottom-footer {
  width: 100cw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  background-color: var(--main-black);
}

.copyright {
  background-color: var(--main-black);
  padding: 0 1rem;
  height: 100%;
  width: 100%;
}

.footer-text {
  text-align: center;
  margin-bottom: 0.25rem;
  font-family: var(--ff-teko);
  letter-spacing: 0.04rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--dark-two);
}

.chat-btn {
  color: var(--light-two);
  background: var(--main-black);
  font-size: 1rem;
  padding: 0.3rem 0.7rem;
  cursor: pointer;
  width: 100%;
  visibility: hidden;
}

.chat-btn:hover {
  background: var(--dark-one);
  color: var(--light-one);
}

.work-together {
  width: 100%;
  padding: 0 1rem;
  height: 100%;
  text-align: center;
}

.email-body {
  width: 100%;
  height: 100%;
  background-color: var(--main-black);
}

.logo-name {
  color: var(--light-two);
}

@media (max-width: 900px) {
  .bottom-footer {
    grid-template-columns: 1fr;
  }

  .chat-btn{
    visibility: none;
  }
}

@media (max-width: 900px) {
  .copyright {
    height: 50vh;
  }

  .email-body {
    height: 50vh;
  }
}
