.download-title{
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  line-height: 1;
  color: var(--light-one);
  overflow-x: visible;
  z-index: 3;
  text-align: center;
}

.download-link{
  margin-top: 3rem;
  margin-bottom: 6rem;
  font-size: 1rem;
  line-height: 1;
  color: var(--light-one);
  overflow-x: visible;
  z-index: 3;
  text-align: center;
}

.download-link a {
  color: green;
}

.download-link a:hover {
  color: pink;
}

.resume-box {
  margin: 2rem;
  background-color: var(--main-dark);
  margin-top: 6rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 6rem;
}
