@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Teko:wght@300&display=swap);
.capabilities-section {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: auto;
    background-color: var(--light-one);
    padding: 1rem;
    margin-top: 2rem;
    width: 100%;
    height: 100%;
}

@media (max-width: 1400px) {
    .capabilities-section {
        grid-template-columns: repeat(6, 1fr);
    }
}

@media (max-width: 1200px) {
    .capabilities-section {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media (max-width: 1000px) {
    .capabilities-section {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 700px) {
    .capabilities-section {
        grid-template-columns: repeat(2, 1fr);
    }
}

.tech {
    min-height: 20vh;
    min-width: 10vw;
}

@media (max-width: 1000px) {
    .tech {
        min-height: 18vh;
    }
}
nav {
  width: 100%;
  position: relative;
  z-index: 50;
}

nav .container {
  display: flex;
  justify-content: space-between;
  height: 6rem;
  align-items: center;
}

.logo {
  width: 100px;
  display: flex;
  align-items: center;
}

.links ul {
  display: flex;
}

.links a {
  display: inline-block;
  padding-left: 3rem;
  color: var(--light-one);
  text-transform: uppercase;
  font-weight: 400;
  font-family: var(--ff-teko);
  font-size: 1.5rem;
  letter-spacing: 1.5px;
  transition: 0.3s;
}

.links a.active {
  background-color: var(--main-color);
  color: var(--light-one);
  border-radius: 2rem;
  font-size: 1rem;
  padding: 0.9rem 2.1rem;
  margin-left: 1rem;
}

.links a:hover {
  color: var(--hover-orange);
}

.links a.active:hover {
  color: var(--light-one);
  background-color: var(--hover-color);
}

.hamburger-menu {
  width: 2.7rem;
  height: 3rem;
  z-index: 100;
  position: relative;
  display: none;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.hamburger-menu .bar {
  position: relative;
  width: 2.1rem;
  height: 3px;
  border-radius: 3px;
  background-color: var(--light-two);
  transition: 0.5s;
}

.bar:before,
.bar:after {
  content: "";
  position: absolute;
  width: 2.1rem;
  height: 3px;
  border-radius: 3px;
  background-color: var(--light-one);
  transition: 0.5s;
}

.bar:before {
  -webkit-transform: translateY(-9px);
          transform: translateY(-9px);
}

.bar:after {
  -webkit-transform: translateY(9px);
          transform: translateY(9px);
}

nav.open .hamburger-menu .bar {
  background-color: transparent;
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

nav.open .hamburger-menu .bar:before {
  -webkit-transform: translateY(0) rotate(45deg);
          transform: translateY(0) rotate(45deg);
  background-color: var(--light-one);
}

nav.open .hamburger-menu .bar:after {
  -webkit-transform: translateY(0) rotate(-45deg);
          transform: translateY(0) rotate(-45deg);
  background-color: var(--light-one);
}

nav.open .links {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.about {
  overflow: hidden;
  min-height: calc(100vh - 6rem);
  display: grid;
  width: 100%;
  place-items: center;
  background: var(--light-one);
}

.about-section-header{
  padding: 5rem 1rem 10rem 1rem;
  width: 100%;
  background: var(--main-black);
}

.about-section-title{
    color: var(--light-one);
    position: static;
    display: block;
    width: 732px;
    margin-top: 1px;
    margin-right: auto;
    margin-left: auto;
    clear: none;
    font-size: 36px;
    text-align: center;
}

.about-me {
  background: var(--light-one);
  height: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 40rem;
  grid-column-gap: 5rem;
  -webkit-column-gap: 5rem;
          column-gap: 5rem;
  justify-content: center;
}

.about-para-title {
  margin-top: 3rem;
  color: var(--dark-two);
  font-family: var(--ff-teko);
  font-size: 2rem;
  letter-spacing: 0.1rem;
}

.about-text {
  color: var(--light-two);
  font-size: 0.8rem;
  letter-spacing: 0.01rem;
  line-height: 0.5rem;
  font-style: italic;
  text-align: center;
  margin-top: 45rem;
  line-height: 1rem;
  padding: 0 1rem;
}

.about-para {
  margin: 1rem 4rem 3rem 0rem;
  color: var(--dark-one);
  letter-spacing: 0.2rem;
  line-height: 2rem;
}

.about-img {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(/static/media/about-img.6d921d4e.jpg) center/cover no-repeat;
  padding-top: 7rem;
  margin: 2.5rem;
}

.about .text {
  margin-top: 1rem;
}

.skills {
  margin: 1.5rem 0 2.5rem 0;
}

.skill {
  margin: 1rem 0;
}

.skill-title {
  color: #555;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.skill-bar {
  width: 70%;
  height: 8px;
  border-radius: 4px;
  background-color: #ece6fa;
  position: relative;
  overflow: hidden;
}

.skill-progress {
  position: absolute;
  height: 100%;
  width: 0%;
  top: 0;
  left: 0;
  background-color: var(--main-color);
  transition: 1s;
}

.about .column-1 {
  position: relative;
}

.about .column-1:before {
  content: "About";
  position: absolute;
  font-size: 8rem;
  font-weight: 800;
  color: var(--dark-two);
  opacity: 0.04;
  top: 20px;
  left: 0;
  line-height: 0;
}

.about .points {
  width: 300px;
  top: 65%;
  left: 80%;
  opacity: 0.1;
}

@media (max-width: 980px) {
  
  .about-section-title{
    width: 300px;
    font-size: 27px;
  }

  .about-me {
    grid-template-columns: auto;
    height: 100%;
  }
  .about-para{
    margin: 1rem;
  }
  .about-para-title{
    text-align: center;
  }
}

.blogs {
  overflow: hidden;
  min-height: calc(100vh - 6rem);
  display: grid;
  place-items: center;
}

.menu-item {
  padding: 0 40px;
  margin: 5px 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  border: none;
}
.menu-item-wrapper.active {
  border: 1px blue solid;
}
.menu-item.active {
  border: 1px green solid;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

.footer-section {
  margin-top: 6rem;
  background: var(--light-one);
}

.footer {
  display: grid;
  justify-content: center;
  margin-top: 2rem;
  padding-top: 2rem;
  background: var(--dark-one);
}

.bottom-footer {
  width: 100cw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  background-color: var(--main-black);
}

.copyright {
  background-color: var(--main-black);
  padding: 0 1rem;
  height: 100%;
  width: 100%;
}

.footer-text {
  text-align: center;
  margin-bottom: 0.25rem;
  font-family: var(--ff-teko);
  letter-spacing: 0.04rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--dark-two);
}

.chat-btn {
  color: var(--light-two);
  background: var(--main-black);
  font-size: 1rem;
  padding: 0.3rem 0.7rem;
  cursor: pointer;
  width: 100%;
  visibility: hidden;
}

.chat-btn:hover {
  background: var(--dark-one);
  color: var(--light-one);
}

.work-together {
  width: 100%;
  padding: 0 1rem;
  height: 100%;
  text-align: center;
}

.email-body {
  width: 100%;
  height: 100%;
  background-color: var(--main-black);
}

.logo-name {
  color: var(--light-two);
}

@media (max-width: 900px) {
  .bottom-footer {
    grid-template-columns: 1fr;
  }

  .chat-btn{
    visibility: none;
  }
}

@media (max-width: 900px) {
  .copyright {
    height: 50vh;
  }

  .email-body {
    height: 50vh;
  }
}

.outside-work {
  margin-left: 3rem;
}

.notebook-title {
  color: var(--light-two);
  font-size: 3rem;
  font-family: var(--ff-teko);
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 4rem;
  width: 16rem;
}

.notebook {
  display: flex;
  height: 100%;
  margin: 1rem 2rem;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(/static/media/notebook.7d2a13e7.jpg) center/cover no-repeat;
}

.check-out-btn {
  margin: 2rem auto;
}

.contact {
  height: 1000px;
}

.contact-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.contact-button-panel {
  display: flex;
  flex-direction: column;
}

.notebook-add {
  padding: 3rem;
}

.contact-info {
  color: var(--light-one);
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.email {
  font-size: 1rem;
}

.icon {
  size: 100rem;
}

.drop-message,
.download-resume {
  margin-bottom: 2rem;
  width: 70%;
  margin-top: 0;
  text-align: center;
}

@media (max-width: 992px) {
  .email {
    font-size: 0.8rem;
  }
  .contact-body {
    grid-template-columns: auto;
  }
  .notebook-add {
    padding: 0;
  }
}

@media (max-width: 776px) {
  .contact {
    height: 100%;
  }
  .drop-message,
  .download-resume {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .email {
    font-size: 0.6rem;
  }
}

header {
  width: 100%;
  background: var(--main-black);
  overflow: hidden;
  position: relative;
}

.header-content .container.grid-2 {
  grid-template-columns: 2.5fr 2.5fr;
  min-height: calc(100vh - 5rem);
  text-align: left;
}

.header-btn {
  margin-bottom: 2rem;
  margin-left: 0.5rem;
}

.header-title, .explore-text {
  margin-top: 3rem;
  font-size: 3rem;
  line-height: 1;
  color: var(--light-one);
  overflow-x: visible;
  z-index: 3;
}

.explore-text{
  font-family: var(--ff-teko);
  text-align: center;
}

.header-content .description-text {
  margin: 2.15rem 0;
}

.header-content .image .img-element {
  max-width: 750px;
  z-index: -1;
}

.intro-img {
  display: flex;
  flex-direction: column;
}

.intro-cmnt {
  justify-self: end;
  margin-left: 25rem;
  color: var(--hover-orange);
}

header .points1 {
  width: 420px;
  bottom: -75px;
  left: -150px;
}

header .points2 {
  width: 70%;
  top: 65%;
  left: 71%;
}

header .square {
  right: 68%;
  top: 71%;
}

header .triangle {
  right: 7%;
  bottom: 75%;
}

header .xshape {
  right: 4%;
  bottom: 50%;
}

header .half-circle1 {
  left: 50%;
  bottom: 82%;
}

header .half-circle2 {
  left: 5%;
  top: 67%;
}

header .wave1 {
  bottom: 75%;
  left: 20%;
}

header .wave2 {
  bottom: 8%;
  right: 55%;
}

header .circle {
  left: 38%;
  bottom: 63%;
}

.letters {
  position: absolute;
  width: 13.5%;
  top: -5px;
  left: 15px;
  opacity: 0.06;
}

.description-text {
  color: var(--dark-two);
  transition: 0.3s;
  font-size: 1.25rem;
  line-height: 1.6;
}

.social-media {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 0.75rem;
}

.social-icon .social-svg:hover {
  background-color: var(--hover-orange);
}

.line {
  margin-top: 2rem;
  width: 200px;
  background: var(--hover-orange);
  border: 0.1rem solid var(--hover-orange);
}

@media (max-width: 980px) {
  .header-content .container.grid-2 {
    display: flex;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.project {
  width: 95vw;
  min-height: 60vh;
  margin: 1rem 0.5rem;
  color: var(--light-one);
  padding-left: 2rem;
  padding-top: 3rem;
  overflow-y: hidden;
}

.project-stack-header {
  display: flex;
  justify-content: space-between;
}

.project-stack {
  color: var(--dark-two);
  text-transform: uppercase;
  font-family: var(--ff-teko);
  font-size: 2rem;
  justify-content: flex-start;
}

.git-fork {
  justify-self: flex-end;
  color: var(--light-one);
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.project-title {
  text-transform: uppercase;
  line-height: 4rem;
  margin: 3rem 0;
  margin-top: 4rem;
  font-size: 5rem;
  letter-spacing: 0.1rem;
  font-family: var(--ff-teko);
}

.view-site {
  margin-top: 3rem;
  width: 15rem;
  text-align: center;
}

@media (max-width: 700px) {
  .project {
    max-height: 100vh;
  }

  .project-title {
    font-size: 4.5rem;
    margin-top: 4rem;
    margin-bottom: 3rem;
  }

  .view-site {
    margin-bottom: 3rem;
  }
}

@media (max-width: 400px) {
  .view-site {
    width: 12rem;
  }
}

.projects-section {
  margin: 1rem;
  display: grid;
  justify-content: center;
  scroll-behavior: smooth;
}

.more-folio {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  align-content: center;
  height: 8vh;
  align-items: center;
  color: var(--dark-one);
}

.github-repo-btn{
  width: 100%;
  margin: 2rem;
  color: var(--dark-one);
  text-align: center;
}

@media (max-width: 700px) {
  .projects-section{
    margin: 0;
  }
}

.download-title{
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  line-height: 1;
  color: var(--light-one);
  overflow-x: visible;
  z-index: 3;
  text-align: center;
}

.download-link{
  margin-top: 3rem;
  margin-bottom: 6rem;
  font-size: 1rem;
  line-height: 1;
  color: var(--light-one);
  overflow-x: visible;
  z-index: 3;
  text-align: center;
}

.download-link a {
  color: green;
}

.download-link a:hover {
  color: pink;
}

.resume-box {
  margin: 2rem;
  background-color: var(--main-dark);
  margin-top: 6rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 6rem;
}

.readme {
  background-color: var(--background-color);
  color: var(--foreground-color);
}

.modelcontainer {
  width: 80%;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.modelcontainer h1, h2, h3 {
  color: var(--light-one);
  margin-top: 20px;
  margin-bottom: 20px;
}

.modelcontainer p{
  margin: 20px;
}

.modelcontainer ol{
  margin-left: 30px;
}

.modelcontainer ul{
  margin-left: 30px;
}

pre {
  background: var(--dark-one);
  color: var(--foreground-color);
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  margin: 20px;
}

code {
  color: var(--hover-orange);  
  padding: 2px 4px;
  border-radius: 3px;
}

.navigate-btn{
  position: absolute;
  top: 25px;
  right: 0.2%;
  background: var(--dark-one);
  color: var(--hover-orange); 
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s;
}

.download-btn {
  position: absolute;
  top: 27px;
  right: 71%;
  background: var(--light-one);
  color: var(--dark-one);
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s;
}

.download-btn:hover {
  background: orange;
  color: black;
}

.navigate-btn:hover {
  background: orange;
  color: black;
}

@media (max-width: 1080px) {
  .download-btn {
    position: absolute;
    top: 27px;
    right: 50%;
    background: var(--light-one);
    color: var(--dark-one);
    padding: 5px 10px;
    text-decoration: none;
    border-radius: 5px;
    transition: background 0.3s;
  }  
}

@media (max-width: 900px) {
  .download-btn {
    visibility: hidden;
  }  
}
:root {
  /* Background and foreground colors */
  --background-color: #181818;   /* Dark black background */
  --foreground-color: #fff;      /* White foreground */

  /* Shades of gray */
  --dark-one: #333;
  --dark-two: #7a7a7a;
  --main-black: #181818;
  --light-one: #f9fafb;
  --light-two: #f6f7fb;
  --light-three: #f6f7fb;

  /* Hover colors */
  --hover-color: #162b64;        /* Dark blue hover */
  --hover-orange: #cfaa5a;       /* Dark orange hover */

  /* Font family */
  --ff-teko: "Teko", sans-serif;
}



.app {
  background: #181818;
  background: var(--main-black);
}

/* Genral Styles */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

.container {
  background: #181818;
  background: var(--main-black);
  position: relative;
  z-index: 5;
  max-width: 92rem;
  padding: 0 4rem;
  margin: 0 auto;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 1.25rem;
  color: #7a7a7a;
  color: var(--dark-two);
  line-height: 1.6;
}

.column-1 {
  margin-right: 1.5rem;
}

.column-2 {
  margin-left: 1.5rem;
}

.image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.z-index {
  position: relative;
  z-index: 2;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}

.overlay .shape {
  width: auto;
  width: initial;
  opacity: 0.13;
  position: absolute;
}

.overlay.overlay-lg .shape {
  height: 55px;
}

.overlay.overlay-lg .shape.wave {
  height: auto;
  height: initial;
  width: 88px;
}

.overlay.overlay-lg .shape.xshape {
  height: 38px;
}

.overlay.overlay-sm .shape {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  opacity: 0.15;
  height: 40px;
}

.overlay.overlay-sm .shape.wave {
  height: auto;
  height: initial;
  width: 70px;
}

.overlay.overlay-sm .shape.xshape {
  height: 30px;
}

.points {
  opacity: 0.3;
  position: absolute;
}

.section {
  padding: 7rem 0;
  overflow: hidden;
}

.section-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.title {
  color: #f9fafb;
  color: var(--light-one);
  position: relative;
  display: inline-block;
  padding-bottom: 1rem;
  line-height: 1;
  font-size: 2.8rem;
  margin-bottom: 0.6rem;
}

.title:before {
  content: attr(data-title);
  display: block;
  margin-bottom: 0.4rem;
  color: #7a7a7a;
  color: var(--dark-two);
  font-size: 1.15rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.title:after {
  content: "";
  position: absolute;
  width: 90px;
  height: 5px;
  border-radius: 3px;
  background-color: #7a7a7a;
  background-color: var(--dark-two);
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.section-header .text {
  max-width: 600px;
  margin: 0 auto;
}

.title-sm {
  color: #333;
  color: var(--dark-one);
  font-weight: 600;
  font-size: 1.6rem;
}

.points-sq {
  width: 210px;
}

.btn {
  display: inline-block;
  padding: 0.85rem 2rem;
  color: #f9fafb;
  color: var(--light-one);
  letter-spacing: 0.1rem;
  font-family: "Teko", sans-serif;
  font-family: var(--ff-teko);
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 500;
  border: #fff solid 0.12rem;
  transition: 0.3s;
}

.btn:hover {
  background-color: #f9fafb;
  background-color: var(--light-one);
  color: #181818;
  color: var(--main-black);
  border: #cfaa5a solid 0.12rem;
  border: var(--hover-orange) solid 0.12rem;
}

.btn.small {
  padding: 0.7rem 1.8rem;
  font-size: 1rem;
}

/* End Genral Styles */

/* Responsive */

@media (max-width: 1280px) {
  .text {
    font-size: 1rem;
  }

  .title {
    font-size: 2.1rem;
    padding-bottom: 0.65rem;
  }

  .title:before {
    margin-bottom: 0.2rem;
    font-size: 0.8rem;
    letter-spacing: 2px;
  }

  .title:after {
    height: 3.5px;
    width: 70px;
  }

  .title-sm {
    font-size: 1.2rem;
  }

  .btn {
    padding: 0.75rem 1.7rem;
    font-size: 0.8rem;
  }

  .btn.small {
    padding: 0.65rem 1.6rem;
    font-size: 0.7rem;
  }

  .section-header .text {
    max-width: 500px;
  }

  .section {
    padding: 4.5rem 0;
  }

  .column-1 {
    margin-right: 1rem;
  }

  .column-2 {
    margin-left: 1rem;
  }

  .overlay.overlay-lg .shape {
    height: 35px;
  }

  .overlay.overlay-lg .shape.wave {
    height: auto;
    height: initial;
    width: 63px;
  }

  .overlay.overlay-lg .shape.xshape {
    height: 24px;
  }

  .overlay.overlay-sm .shape {
    height: 24px;
  }

  .overlay.overlay-sm .shape.wave {
    height: auto;
    height: initial;
    width: 39px;
  }

  .overlay.overlay-sm .shape.xshape {
    height: 19px;
  }

  header .points1 {
    width: 270px;
    bottom: -50px;
    left: -75px;
  }

  header .points2 {
    width: 340px;
  }

  header .letters {
    width: 11%;
  }

  .header-content .image .img-element {
    max-width: 500px;
  }

  nav .container {
    height: 4.5rem;
  }

  .header-content .container.grid-2 {
    min-height: calc(100vh - 4.5rem);
    padding-bottom: 1.5rem;
  }

  .logo {
    width: 65px;
  }

  .links a {
    font-size: 1.5rem;
    padding: 0.65rem 0.7rem;
  }

  .links a.active {
    font-size: 0.7rem;
    padding: 0.7rem 1.7rem;
    margin-left: 0.6rem;
  }

  .header-title {
    font-size: 2.6rem;
  }

  .header-content .text {
    margin: 1.1rem 0;
  }

  .card {
    min-height: 390px;
    max-width: 290px;
    padding: 2.2rem 1.5rem;
  }

  .icon {
    width: 65px;
    margin-bottom: 0.8rem;
  }

  .card .text {
    font-size: 0.9rem;
    margin: 1rem 0;
  }

  .card:before {
    font-size: 5rem;
  }

  .card-wrap {
    margin: 1rem 0.6rem;
  }

  .services .points1 {
    left: -60px;
  }

  .points-sq {
    width: 150px;
  }

  .grid {
    margin: 0.8rem 0;
  }

  .grid-item {
    padding: 0.8rem;
  }

  .gallery-image {
    height: 250px;
    max-width: 325px;
  }

  .gallery-image .img-overlay {
    padding: 1.4rem 2rem;
  }

  .plus:before,
  .plus:after {
    width: 2.8rem;
  }

  .img-overlay h3 {
    font-size: 1.1rem;
  }

  .img-overlay h5 {
    font-size: 0.8rem;
  }

  .background-bg {
    height: 370px;
  }

  .filter-btn {
    padding: 0.6rem 1.45rem;
    font-size: 0.7rem;
  }

  .about img {
    max-width: 420px;
  }

  .about .text {
    margin-top: 0.7rem;
  }

  .skills {
    margin: 0.9rem 0 1.7rem 0;
  }

  .skill-title {
    font-size: 1rem;
  }

  .skill-bar {
    height: 6px;
  }

  .about .column-1:before {
    font-size: 6rem;
    top: 13px;
  }

  .about .points {
    width: 230px;
    left: 65%;
  }

  .record-circle {
    width: 150px;
    height: 150px;
    border-width: 3px;
  }

  .record-circle .number {
    font-size: 1.8rem;
  }

  .record-circle .sub-title {
    font-size: 0.9rem;
  }

  .blog-wrap {
    margin: 0.7rem 0.5rem;
  }

  .blog-card {
    max-width: 315px;
  }

  .blog-image {
    height: 200px;
  }

  .blog-content {
    padding: 1.8rem 1.7rem 1.9rem 1.7rem;
  }

  .blog-info {
    margin-bottom: 0.2rem;
  }

  .blog-info > * {
    font-size: 0.75rem !important;
  }

  .blog-user i {
    font-size: 0.8rem;
  }

  .blog-text {
    font-size: 0.9rem;
    margin: 0.2rem 0 0.8rem 0;
  }

  .rate {
    font-size: 1.15rem;
    margin-bottom: 1.1rem;
  }

  .review-text {
    font-size: 1.05rem;
  }

  .review-info {
    margin-top: 1.1rem;
  }

  .review-name {
    font-size: 1.05rem;
  }

  .review-job {
    font-size: 0.85rem;
  }

  .quote {
    font-size: 6.5rem;
  }

  .review-nav {
    font-size: 1.55rem;
  }

  .swiper-button-next {
    right: 2.5rem;
  }

  .swiper-button-prev {
    right: 4.5rem;
  }

  .contact {
    padding: 9rem 0 6rem 0;
  }

  .contact-form {
    padding-left: 1rem;
  }

  .contact-info {
    padding-right: 1rem;
  }

  .contact-box {
    padding: 3.6rem 3rem;
  }

  .information-wrap {
    margin-top: 1.2rem;
  }

  .contact-icon {
    min-width: 50px;
    height: 50px;
    font-size: 1rem;
    margin-right: 0.8rem;
  }

  .contact-icon i {
    line-height: 50px;
  }

  .info-text {
    font-size: 1.05rem;
  }

  .contact-input {
    font-size: 0.9rem;
    padding: 0.9rem 1.6rem;
    margin: 0.4rem 0;
  }

  .hireme {
    padding: 8rem 0;
  }

  .footer {
    padding: 4rem 0;
  }

  .footer-links a {
    font-size: 1rem;
  }

  .footer .title-sm {
    margin-bottom: 0.5rem;
  }

  .footer-input-wrap {
    height: 36px;
    margin-top: 0.8rem;
  }

  .footer-input {
    font-size: 0.9rem;
    padding: 0 1.2rem;
  }

  .input-arrow {
    font-size: 1.2rem;
  }

  .copyright .text {
    font-size: 0.95rem;
  }

  .followme h3 {
    font-size: 1.05rem;
  }

  .followme .footer-line {
    margin: 0 0.8rem;
  }

  .social-media a {
    font-size: 1.05rem;
  }

  .back-btn-wrap {
    margin-left: 1.3rem;
  }

  .back-btn {
    width: 43px;
    height: 43px;
    font-size: 0.9rem;
  }

  .back-btn-wrap:before {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 850px) {
  .header-content .image .img-element {
    display: none;
  }

  .grid-2 {
    grid-template-columns: 1fr !important;
  }

  .column-1 {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .column-2 {
    margin-left: 0;
    margin-top: 1rem;
  }

  .hamburger-menu {
    display: flex;
    background-color: #181818;
    background-color: var(--main-black);
    justify-content: center;
  }

  .header-content {
    margin-top: 1rem;
  }

  .header-title {
    font-size: 2.3rem;
  }

  .header-content .image {
    max-width: 400px;
    margin: 0 auto;
  }

  header .column-1 {
    max-width: 550px;
    margin: 0 auto;
  }

  .links {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    /* background-color: #252525; */
    background-color: #f9fafb;
    background-color: var(--light-one);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: 0.5s;
  }

  .links ul {
    flex-direction: column;
  }

  .links a {
    color: #181818;
    color: var(--main-black);
    font-size: 2rem;
  }

  .links a.active {
    margin-left: 0;
    margin: 0.5rem 0;
  }

  .header-content .container.grid-2 {
    min-height: 80vh;
  }

  .letters,
  header .half-circle2,
  header .points1,
  header .wave2 {
    display: none;
  }

  header .half-circle1 {
    bottom: 55%;
    left: 23%;
  }

  header .circle {
    left: 8%;
    bottom: 40%;
  }

  header .wave1 {
    bottom: 87%;
    left: 75%;
  }

  header .square {
    top: auto;
    top: initial;
    bottom: 5%;
    left: 13%;
  }

  header .triangle {
    right: 14%;
    bottom: 63%;
  }

  header .points2 {
    width: 280px;
    bottom: -10px;
    left: 60%;
  }

  header .xshape {
    left: 90%;
    top: 54%;
  }

  .grid-item {
    width: 50%;
  }

  .gallery-image {
    height: 210px;
    max-width: 270px;
  }

  .background-bg {
    height: 340px;
  }

  .portfolio .half-circle1,
  .portfolio .triangle,
  .portfolio .half-circle2 {
    display: none;
  }

  .portfolio .square {
    top: 10%;
    left: 14%;
  }

  .portfolio .wave {
    top: 33%;
  }

  .portfolio .circle {
    right: 20%;
  }

  .portfolio .xshape {
    top: 25%;
  }

  .skill-bar {
    width: 100%;
  }

  .about .column-1:before {
    font-size: 5rem;
  }

  .about .points {
    top: 60%;
    left: 59%;
  }

  .records .wave1,
  .records .triangle {
    display: none;
  }

  .records .container {
    grid-template-columns: repeat(2, 1fr);
  }

  .records .wave2 {
    left: 48%;
  }

  .records .sqaure2 {
    bottom: 45%;
    right: 50px;
  }

  .testi-content {
    margin-top: 1.7rem;
  }

  .testi-content .image {
    max-width: 500px;
    margin: 0 auto;
  }

  .review-nav {
    bottom: 3rem;
  }

  .contact-box {
    grid-template-columns: 1fr;
    padding: 3.2rem 2.7rem;
  }

  .contact-info {
    padding-right: 0;
    padding-bottom: 0.5rem;
  }

  .contact-form {
    padding-left: 0;
    padding-top: 0.5rem;
  }

  .information-wrap {
    margin-top: 0.7rem;
  }

  .information:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  .contact-input.textarea {
    min-height: 220px;
  }

  .contact:before {
    height: 25%;
  }

  .contact:after {
    height: 75%;
    top: 25%;
  }

  .footer .grid-4 {
    grid-template-columns: repeat(2, 1fr);
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
  }

  .footer-about {
    grid-column: 1 / 3;
  }

  .footer-newstletter {
    grid-column: 1 / 3;
  }

  .grid-4-col {
    margin: 1rem 0;
    padding: 0;
  }

  .footer-input-wrap {
    max-width: 300px;
    margin: 0.95rem auto 0 auto;
    grid-template-columns: auto 45px;
  }

  .bottom-footer {
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 1.5rem 0 0 0;
    margin-top: 2.5rem;
    position: relative;
  }

  .bottom-footer:before {
    content: "";
    position: absolute;
    width: 90%;
    max-width: 500px;
    height: 1px;
    background-color: #7b7b7b;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }

  .followme-wrap {
    margin-top: 0.8rem;
  }
}

@media (max-width: 600px) {
  .grid-item {
    width: 100%;
  }
}

@media (max-width: 560px) {
  .container {
    padding: 0 2rem;
  }

  .title {
    font-size: 1.8rem;
  }

  .text {
    font-size: 0.92rem;
  }

  .overlay.overlay-lg .shape {
    height: 28px;
  }

  .overlay.overlay-lg .shape.wave {
    height: auto;
    height: initial;
    width: 52px;
  }

  .overlay.overlay-lg .shape.xshape {
    height: 22px;
  }

  .records .container {
    grid-template-columns: 1fr;
  }

  .records .half-circle {
    top: 50%;
    left: 50px;
  }

  .records .wave2 {
    bottom: 50%;
    right: 20px;
    left: auto;
    left: initial;
  }

  .records .xshape {
    right: 70px;
  }

  .records .square2 {
    bottom: 90px;
    right: 40px;
  }

  .testi-content {
    margin-top: 1rem;
  }

  .review {
    padding: 1.5rem 1rem;
  }

  .review-text {
    font-size: 0.9rem;
  }

  .review-nav {
    bottom: 1.5rem;
    font-size: 1.3rem;
  }

  .swiper-button-next {
    right: 1.3rem;
  }

  .swiper-button-prev {
    right: 3rem;
  }

  .contact {
    padding: 8rem 0 5rem 0;
  }

  .contact-box {
    padding: 2.3rem 2rem;
    border-radius: 30px;
  }

  .contact:before {
    background-size: 200%;
  }

  .contact-form .row {
    grid-column-gap: 0.3rem;
  }

  .contact-input {
    font-size: 0.8rem;
    padding: 0.8rem 1.4rem;
    margin: 0.25rem 0;
  }
}

/* End Responsive */

.notfoundsection {
  background-color: var(--background-color);
  color: var(--foreground-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.error-code {
  font-size: 6rem;
  line-height: 1;
  color: var(--light-one);
  z-index: 3;
  text-align: center;
}

.error-title {
  margin-top: 1rem;
  font-size: 2rem;
  color: var(--light-one);
  z-index: 3;
  text-align: center;
}

.redirect-link {
  margin-top: 2rem;
  font-size: 2rem;
  color: var(--light-one);
  z-index: 3;
  text-align: center;
}

.redirect-link a {
  color: green;
  text-decoration: none;
}

.redirect-link a:hover {
  color: pink;
}

.error-box {
  background-color: var(--main-black);
  padding: 2rem;
  text-align: center;
  border-radius: 10px;
}


