.projects-section {
  margin: 1rem;
  display: grid;
  justify-content: center;
  scroll-behavior: smooth;
}

.more-folio {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  align-content: center;
  height: 8vh;
  align-items: center;
  color: var(--dark-one);
}

.github-repo-btn{
  width: 100%;
  margin: 2rem;
  color: var(--dark-one);
  text-align: center;
}

@media (max-width: 700px) {
  .projects-section{
    margin: 0;
  }
}
