.outside-work {
  margin-left: 3rem;
}

.notebook-title {
  color: var(--light-two);
  font-size: 3rem;
  font-family: var(--ff-teko);
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 4rem;
  width: 16rem;
}

.notebook {
  display: flex;
  height: 100%;
  margin: 1rem 2rem;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url("../../assets/img/notebook.jpg") center/cover no-repeat;
}

.check-out-btn {
  margin: 2rem auto;
}
