.notfoundsection {
  background-color: var(--background-color);
  color: var(--foreground-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.error-code {
  font-size: 6rem;
  line-height: 1;
  color: var(--light-one);
  z-index: 3;
  text-align: center;
}

.error-title {
  margin-top: 1rem;
  font-size: 2rem;
  color: var(--light-one);
  z-index: 3;
  text-align: center;
}

.redirect-link {
  margin-top: 2rem;
  font-size: 2rem;
  color: var(--light-one);
  z-index: 3;
  text-align: center;
}

.redirect-link a {
  color: green;
  text-decoration: none;
}

.redirect-link a:hover {
  color: pink;
}

.error-box {
  background-color: var(--main-black);
  padding: 2rem;
  text-align: center;
  border-radius: 10px;
}
