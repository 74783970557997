.about {
  overflow: hidden;
  min-height: calc(100vh - 6rem);
  display: grid;
  width: 100%;
  place-items: center;
  background: var(--light-one);
}

.about-section-header{
  padding: 5rem 1rem 10rem 1rem;
  width: 100%;
  background: var(--main-black);
}

.about-section-title{
    color: var(--light-one);
    position: static;
    display: block;
    width: 732px;
    margin-top: 1px;
    margin-right: auto;
    margin-left: auto;
    clear: none;
    font-size: 36px;
    text-align: center;
}

.about-me {
  background: var(--light-one);
  height: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 40rem;
  column-gap: 5rem;
  justify-content: center;
}

.about-para-title {
  margin-top: 3rem;
  color: var(--dark-two);
  font-family: var(--ff-teko);
  font-size: 2rem;
  letter-spacing: 0.1rem;
}

.about-text {
  color: var(--light-two);
  font-size: 0.8rem;
  letter-spacing: 0.01rem;
  line-height: 0.5rem;
  font-style: italic;
  text-align: center;
  margin-top: 45rem;
  line-height: 1rem;
  padding: 0 1rem;
}

.about-para {
  margin: 1rem 4rem 3rem 0rem;
  color: var(--dark-one);
  letter-spacing: 0.2rem;
  line-height: 2rem;
}

.about-img {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../../assets/img/about-img.jpg") center/cover no-repeat;
  padding-top: 7rem;
  margin: 2.5rem;
}

.about .text {
  margin-top: 1rem;
}

.skills {
  margin: 1.5rem 0 2.5rem 0;
}

.skill {
  margin: 1rem 0;
}

.skill-title {
  color: #555;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.skill-bar {
  width: 70%;
  height: 8px;
  border-radius: 4px;
  background-color: #ece6fa;
  position: relative;
  overflow: hidden;
}

.skill-progress {
  position: absolute;
  height: 100%;
  width: 0%;
  top: 0;
  left: 0;
  background-color: var(--main-color);
  transition: 1s;
}

.about .column-1 {
  position: relative;
}

.about .column-1:before {
  content: "About";
  position: absolute;
  font-size: 8rem;
  font-weight: 800;
  color: var(--dark-two);
  opacity: 0.04;
  top: 20px;
  left: 0;
  line-height: 0;
}

.about .points {
  width: 300px;
  top: 65%;
  left: 80%;
  opacity: 0.1;
}

@media (max-width: 980px) {
  
  .about-section-title{
    width: 300px;
    font-size: 27px;
  }

  .about-me {
    grid-template-columns: auto;
    height: 100%;
  }
  .about-para{
    margin: 1rem;
  }
  .about-para-title{
    text-align: center;
  }
}
