header {
  width: 100%;
  background: var(--main-black);
  overflow: hidden;
  position: relative;
}

.header-content .container.grid-2 {
  grid-template-columns: 2.5fr 2.5fr;
  min-height: calc(100vh - 5rem);
  text-align: left;
}

.header-btn {
  margin-bottom: 2rem;
  margin-left: 0.5rem;
}

.header-title, .explore-text {
  margin-top: 3rem;
  font-size: 3rem;
  line-height: 1;
  color: var(--light-one);
  overflow-x: visible;
  z-index: 3;
}

.explore-text{
  font-family: var(--ff-teko);
  text-align: center;
}

.header-content .description-text {
  margin: 2.15rem 0;
}

.header-content .image .img-element {
  max-width: 750px;
  z-index: -1;
}

.intro-img {
  display: flex;
  flex-direction: column;
}

.intro-cmnt {
  justify-self: end;
  margin-left: 25rem;
  color: var(--hover-orange);
}

header .points1 {
  width: 420px;
  bottom: -75px;
  left: -150px;
}

header .points2 {
  width: 70%;
  top: 65%;
  left: 71%;
}

header .square {
  right: 68%;
  top: 71%;
}

header .triangle {
  right: 7%;
  bottom: 75%;
}

header .xshape {
  right: 4%;
  bottom: 50%;
}

header .half-circle1 {
  left: 50%;
  bottom: 82%;
}

header .half-circle2 {
  left: 5%;
  top: 67%;
}

header .wave1 {
  bottom: 75%;
  left: 20%;
}

header .wave2 {
  bottom: 8%;
  right: 55%;
}

header .circle {
  left: 38%;
  bottom: 63%;
}

.letters {
  position: absolute;
  width: 13.5%;
  top: -5px;
  left: 15px;
  opacity: 0.06;
}

.description-text {
  color: var(--dark-two);
  transition: 0.3s;
  font-size: 1.25rem;
  line-height: 1.6;
}

.social-media {
  display: flex;
  gap: 1rem;
  margin: 0.75rem;
}

.social-icon .social-svg:hover {
  background-color: var(--hover-orange);
}

.line {
  margin-top: 2rem;
  width: 200px;
  background: var(--hover-orange);
  border: 0.1rem solid var(--hover-orange);
}

@media (max-width: 980px) {
  .header-content .container.grid-2 {
    display: flex;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
