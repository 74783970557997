.capabilities-section {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: auto;
    background-color: var(--light-one);
    padding: 1rem;
    margin-top: 2rem;
    width: 100%;
    height: 100%;
}

@media (max-width: 1400px) {
    .capabilities-section {
        grid-template-columns: repeat(6, 1fr);
    }
}

@media (max-width: 1200px) {
    .capabilities-section {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media (max-width: 1000px) {
    .capabilities-section {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 700px) {
    .capabilities-section {
        grid-template-columns: repeat(2, 1fr);
    }
}
